<template>
  <div>
    <slot name="over-table" />
    <div :class="`position-relative ${responsive ? 'table-responsive' : ''}`">
      <table :class="tableClasses">
        <thead>
          <slot name="thead-top" />
          <tr v-if="header">
            <template v-for="(name, index) in columnNames">
              <th
                :class="[headerClass(index)]"
                :style="headerStyles(index)"
                :key="index"
              >
                <slot :name="`${rawColumnNames[index]}-header`">
                  <div>{{ name }}</div>
                </slot>
              </th>
            </template>
          </tr>
        </thead>

        <draggable
          :list="passedItems"
          tag="tbody"
          handle=".handle"
          :style="clickableRows ? 'cursor:pointer;' : null"
          class="position-relative"
          @end="$emit('update:items', passedItems)"
        >
          <tr
            @click="rowClicked(item, itemIndex + firstItemIndex, $event)"
            :class="item._classes"
            :tabindex="clickableRows ? 0 : null"
            v-for="(item, itemIndex) in currentItems"
            :key="item.id"
          >
            <template v-for="(colName, index) in rawColumnNames">
              <slot
                v-if="$scopedSlots[colName]"
                :name="colName"
                :item="item"
                :index="itemIndex + firstItemIndex"
              />
              <td v-else :class="cellClass(item, colName, index)" :key="index">
                {{ String(item[colName]) }}
              </td>
            </template>
          </tr>
          <tr v-if="!currentItems.length">
            <td :colspan="colspan">
              <slot name="no-items-view">
                <div class="text-center my-5">
                  <h2>
                    {{ noItemsText }}
                    <CIcon name="cilBan" width="30" class="text-danger mb-2" />
                  </h2>
                </div>
              </slot>
            </td>
          </tr>
        </draggable>

        <tfoot v-if="footer && currentItems.length > 0">
          <tr>
            <template v-for="(name, index) in columnNames">
              <th
                :class="[headerClass(index)]"
                :style="headerStyles(index)"
                :key="index"
              >
                <slot :name="`${rawColumnNames[index]}-header`">
                  <div>{{ name }}</div>
                </slot>
              </th>
            </template>
          </tr>
        </tfoot>
        <slot name="footer" :itemsAmount="currentItems.length" />
        <slot name="caption" />
      </table>

      <slot name="loading" v-if="loading">
        <CElementCover
          :boundaries="[
            { sides: ['top'], query: 'td' },
            { sides: ['bottom'], query: 'tbody' }
          ]"
        />
      </slot>
    </div>

    <slot name="under-table" />

    <CPagination
      v-if="pagination"
      v-show="totalPages > 1"
      :activePage.sync="page"
      :pages="totalPages"
      v-bind="typeof pagination === 'object' ? pagination : null"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: {
    items: Array,
    fields: Array,
    itemsPerPage: {
      type: Number,
      default: 20
    },
    activePage: Number,
    pagination: [Boolean, Object],
    addTableClasses: [String, Array, Object],
    responsive: {
      type: Boolean,
      default: true
    },
    size: String,
    dark: Boolean,
    striped: Boolean,
    fixed: Boolean,
    hover: Boolean,
    border: Boolean,
    outlined: Boolean,
    itemsPerPageSelect: [Boolean, Object],
    header: {
      type: Boolean,
      default: true
    },
    footer: Boolean,
    loading: Boolean,
    clickableRows: Boolean,
    noItemsView: Object
  },
  data() {
    return {
      page: this.activePage || 1,
      perPageItems: this.itemsPerPage,
      passedItems: this.items || []
    };
  },
  watch: {
    itemsPerPage(val) {
      this.perPageItems = val;
    },
    items(val, oldVal) {
      if (val && oldVal && this.objectsAreIdentical(val, oldVal)) {
        return;
      }
      this.passedItems = val || [];
    },
    totalPages: {
      immediate: true,
      handler(val) {
        this.$emit("pages-change", val);
      }
    },
    computedPage(val) {
      this.$emit("page-change", val);
    }
  },
  computed: {
    firstItemIndex() {
      return (this.computedPage - 1) * this.perPageItems || 0;
    },
    paginatedItems() {
      return this.passedItems.slice(
        this.firstItemIndex,
        this.firstItemIndex + this.perPageItems
      );
    },
    currentItems() {
      return this.computedPage ? this.paginatedItems : this.passedItems;
    },
    totalPages() {
      return Math.ceil(this.passedItems.length / this.perPageItems) || 1;
    },
    computedPage() {
      return this.pagination ? this.page : this.activePage;
    },
    generatedColumnNames() {
      return Object.keys(this.passedItems[0] || {}).filter(
        el => el.charAt(0) !== "_"
      );
    },
    rawColumnNames() {
      if (this.fields) {
        return this.fields.map(el => el.key || el);
      }
      return this.generatedColumnNames;
    },
    columnNames() {
      if (this.fields) {
        return this.fields.map(f => {
          return f.label !== undefined
            ? f.label
            : this.prettifyName(f.key || f);
        });
      }
      return this.rawColumnNames.map(el => this.prettifyName(el));
    },
    tableClasses() {
      return [
        "table",
        this.addTableClasses,
        {
          [`table-${this.size}`]: this.size,
          "table-dark": this.dark,
          "table-striped": this.striped,
          "table-fixed": this.fixed,
          "table-hover": this.hover,
          "table-bordered": this.border,
          border: this.outlined
        }
      ];
    },
    colspan() {
      return this.rawColumnNames.length;
    },
    noItemsText() {
      const customValues = this.noItemsView || {};
      if (this.passedItems.length) {
        return customValues.noResults;
      }
      return customValues.noItems || "No items";
    }
  },
  methods: {
    prettifyName(name) {
      return name
        .replace(/[-_.]/g, " ")
        .replace(/ +/g, " ")
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    cellClass(item, colName, index) {
      let classes = [];
      if (item._cellClasses && item._cellClasses[colName]) {
        classes.push(item._cellClasses[colName]);
      }
      if (this.fields && this.fields[index]._classes) {
        classes.push(this.fields[index]._classes);
      }
      return classes;
    },
    headerClass(index) {
      const fields = this.fields;
      return fields && fields[index]._classes ? fields[index]._classes : "";
    },
    headerStyles(index) {
      let style = "vertical-align:middle;overflow:hidden;";
      if (this.fields && this.fields[index] && this.fields[index]._style) {
        style += this.fields[index]._style;
      }
      return style;
    },
    rowClicked(item, index, e, detailsClick = false) {
      this.$emit(
        "row-clicked",
        item,
        index,
        this.getClickedColumnName(e, detailsClick),
        e
      );
    },
    getClickedColumnName(e, detailsClick) {
      if (detailsClick) {
        return "details";
      } else {
        const children = Array.from(e.target.closest("tr").children);
        const clickedCell = children.filter(child =>
          child.contains(e.target)
        )[0];
        return this.rawColumnNames[children.indexOf(clickedCell)];
      }
    },
    objectsAreIdentical(obj1, obj2) {
      return (
        obj1.length === obj2.length &&
        JSON.stringify(obj1) === JSON.stringify(obj2)
      );
    }
  }
};
</script>

<style scoped>
thead tr:not(:last-child) th {
  border-bottom: 1px;
}
</style>
